import * as React from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { BidRequest } from '../../../types/bid-as-dealer/BidRequest';
import Popup from '../../controls/Popup';
import { PopupBody, PopupFooter } from '../../controls';
import { Table } from '../../bidding/common/table';
import { IColumnDefinition } from '../../bidding/common/table/types/ColumnDefinition';
import { moneyUtils, formatUtils, isRequesting, isRequestSuccess, isRequestFailed, apiUtils } from '../../../utils';
import { BidLevelDelta } from '../../common/bidding/BidLevelDelta';
import { ColumnBuilder } from '../../bidding/common/table/columns/column-builder/ColumnBuilder';
import { CompanySlim } from '../../../types/company/CompanySlim';
import { AppState } from '../../../types/state/AppState';
import { ApiOperationType } from '../../../types/api-operation/ApiOperationType';
import { apiOperationActions } from '../../../actions';
import { SubmitBidRequestStatus } from './SubmitBidRequestStatus';
import { ReSubmitBidRequestButton } from './ReSubmitBidRequestButton';
import { constants } from '../../../constants';
import { StatusMessageSectionType } from "../../../types/state/NotificationState";
import { StatusMessageSection } from "../../status-message/StatusMessageSection";
import { BuyerPosition } from '../../../types/bwic/BuyerPosition';
import { BwicPositionBase } from '../../../types/bwic/BwicPositionBase';
import { Process } from '../../../types/models/Process';
import { liveBiddingUtils } from '../../../utils/live-bidding.utils';
import { OpenBiddingStatus } from '../../../types/enums/OpenBiddingStatus';
import { FinalIcon } from '../../bidding/common/FinalIcon';
import { AxedIcon } from '../../bidding/common/AxedIcon';
import { InfoTooltip } from '../../common/InfoTooltip';

interface Improvement {
    positionId: number;
    level: number;
    axed: boolean;
    final: boolean;
    commission: number;
    settlementAgent: CompanySlim;
}

interface Props {
    bidRequests: BidRequest[];
    positions: BuyerPosition[];
    improvements: Improvement[];
    isSubmitting: boolean;
    process: Process;
    onConfirmed: () => void;
    onClose: () => void;
    onReSubmitSingle: (positionId: number) => void;
    bidsDueDateUtc: Date
}

export function ConfirmBidRequestsPopup({
    bidRequests,
    positions,
    improvements,
    isSubmitting,
    process,
    onConfirmed,
    onClose,
    onReSubmitSingle,
    bidsDueDateUtc }: Props) {
    const dispatch = useDispatch();

    const [confirmPressed, setConfirmPressed] = React.useState(false);

    const isDirectBidOpetation = (e: ApiOperationType) =>
        e === ApiOperationType.SubmitAxedFinal ||
        e === ApiOperationType.SubmitBidRequest

    const hasPendingApiOperations = useSelector((s: AppState) =>
        s.apiOperation.requests.some(r => isRequesting(r.state) && isDirectBidOpetation(r.event))
    );
    const disabled = isSubmitting || hasPendingApiOperations;
    const finishedApiOperationsList = useSelector((s: AppState) => disabled
        ? []
        : s.apiOperation.requests.filter(r => (isRequestSuccess(r.state) || isRequestFailed(r.state)) && isDirectBidOpetation(r.event))
    );
    const finishedApiOperations = apiUtils.normalize(finishedApiOperationsList, o => o.positionId!, o => o);

    React.useEffect(() => {
        return () => {
            dispatch(apiOperationActions.resetEvent(ApiOperationType.SubmitAxedFinal));
            dispatch(apiOperationActions.resetEvent(ApiOperationType.SubmitBidRequest));
        }
    }, [dispatch]);

    const [position] = positions;

    if (!position) return null;


    const columnDefinitions: IColumnDefinition<{
        bidRequest?: BidRequest,
        position: BuyerPosition,
        improvement: Improvement
    }>[] = [
            {
                columnKey: 'isinCusip',
                renderColumnContent: item => item.position.isinCusip,
                renderColumnHeaderContent: () => 'Identifier',
                className: 'data-list-cell-sm'
            }, {
                columnKey: 'ticker',
                renderColumnContent: item => item.position.ticker,
                renderColumnHeaderContent: () => 'Ticker',
                className: 'data-list-cell-lg'
            }, {
                columnKey: 'size',
                renderColumnContent: item => moneyUtils.money(item.position.size),
                renderColumnHeaderContent: () => 'Bid Size',
                className: 'text-right'
            }, {
                columnKey: 'bid',
                renderColumnContent: item =>
                    <BidLevelDelta
                        currentLevel={item.bidRequest?.level ?? 0}
                        updatedLevel={item.improvement.level}
                    />,
                renderColumnHeaderContent: () => 'Bid',
                className: 'data-list-cell-md cell-bid-level'
            }, {
                columnKey: 'commission',
                renderColumnContent: item => item.improvement.commission.toFixed(constants.commissionDecimalPlaces),
                renderColumnHeaderContent: () => 'Comm, %',
                className: 'data-list-cell-xs text-right',
            }, {
                columnKey: 'total',
                renderColumnContent: item => formatUtils.formatBid(item.improvement.level + item.improvement.commission),
                renderColumnHeaderContent: () => <>Final Bid<InfoTooltip text="Final bid includes commission." /></>,
                headerClassName: 'text-right',
                bodyClassName: 'text-right text-bold'
            }, {
                columnKey: 'axed',
                renderColumnContent: item => <>{item.improvement.axed && <AxedIcon />}</>,
                renderColumnHeaderContent: () => '',
                className: 'data-list-cell-xxxs padding-l-0 text-center'
            }, {
                columnKey: 'final',
                renderColumnContent: item => <>{item.improvement.final && <FinalIcon />}</>,
                renderColumnHeaderContent: () => '',
                className: 'data-list-cell-xxxs padding-l-0 text-center'
            }, {
                columnKey: 'submit-status',
                renderColumnHeaderContent: () => 'Status',
                renderColumnContent: item =>
                    <SubmitBidRequestStatus
                        position={item.position}
                        isSubmitting={isSubmitting}
                        process={process}
                        bidsDueDateUtc={bidsDueDateUtc}
                    />,
                className: 'data-list-cell-xl cell-submit-status'
            }, {
                columnKey: 'action-buttons',
                renderColumnHeaderContent: () => '',
                renderColumnContent: item =>
                    liveBiddingUtils.checkLiveBiddingStage2Expired(process, bidsDueDateUtc, position.latestBidSubmission)
                        || process.stagedBiddingStatus === OpenBiddingStatus.improvementRoundEnded ? null : <ReSubmitBidRequestButton
                        position={item.position}
                        isSubmitting={isSubmitting}
                        onClick={onReSubmitSingle}
                    />,
                className: 'data-list-cell-sm text-right',
            }
        ];

    const getGroupedData = () =>
        improvements
            .map(improvement => {
                const position = (positions as BwicPositionBase[]).find(p => p.id === improvement.positionId);
                const bidRequest = bidRequests.find(b => b.positionId === improvement.positionId);

                return position ? { bidRequest, position: position as BuyerPosition, improvement } : undefined;
            })
            .filter(i => i != null);

    const handleConfirm = () => {
        setConfirmPressed(true);
        onConfirmed();
    }

    return (
        <Popup
            modalClass="confirm-buyer-bids-popup"
            title="Bid Confirmation"
            onClose={disabled ? () => { } : onClose}
            disabled={disabled}
        >
            <PopupBody>
                <StatusMessageSection type={StatusMessageSectionType.Alert}>
                    A bid cannot be revoked or decreased;<br />
                    A bid must be good for at least 30 minutes beyond the bidding window;<br />
                    No security may be converted from 144a to Reg S or vice versa. Buyers are providing firm bids for the securities listed in the format identified by a seller.
                    <div className="text-medium">Seller will be notified if a bidder is bidding over themselves, but the KTX ATS platform does not enforce trading at the prior level.</div>
                </StatusMessageSection>
                <Table
                    className="data-list-striped"
                    columns={columnDefinitions.map(c => new ColumnBuilder(c))}
                    dataItems={getGroupedData()}
                    createSecurityCustomClassName={
                        (dataItem: { position: BuyerPosition }) => {
                            const operation = finishedApiOperations[dataItem.position.id];

                            return cn({
                                'tradedaway':
                                    operation &&
                                    isRequestFailed(operation.state) &&
                                    operation.result &&
                                    operation.result.statusCode !== 409 &&
                                    operation.result.statusCode !== 300
                            })
                        }
                    }
                />
            </PopupBody>
            <PopupFooter>
                {
                    confirmPressed &&
                    <button type="button" className="btn btn-ghost" disabled={disabled} onClick={onClose}>Close</button>
                }
                {
                    !confirmPressed &&
                    <>
                        <button type="button" className="btn btn-ghost" disabled={disabled} onClick={onClose}>Cancel</button>
                        <button
                            type="button"
                            className="btn btn-main"
                            disabled={disabled} onClick={handleConfirm}
                        >
                            Confirm
                        </button>
                    </>
                }
            </PopupFooter>
        </Popup>
    );
}
