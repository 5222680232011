import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../types/state/AppState';
import { Bank } from '../../types/banks/Bank';
import BanksContext from './banksContext';
import { BanksState } from '../../types/state/BanksState';
import { BanksLabels } from './BanksLabels';
import { BanksAlertsButton } from './BanksAlertsButton';
import { banksActions } from '../../actions/banks.actions';
import { UserConfigState } from '../../types/state/UserConfigState';
import { hasBankAnalyticsAlertsChangeAccess, withTurnedOnAlerts } from '../../utils/amr-pipeline.utils';
import { user } from '../../user';
import { SubscriptionFeature } from '../../types/billing/SubscriptionFeature';
import { bankImApRequiredFeatures } from '../../constants/banks/bankImApRequiredFeatures';

interface BanksHeaderProps {
    bank: Bank;
    edit: boolean;
    onEdit?: () => void;
    onSave?: () => void;
    onCancel?: () => void;
}

const BanksHeader = ({ bank, edit, onEdit, onSave, onCancel }: BanksHeaderProps) => {
    const dispatch = useDispatch();
    const { canEdit } = useContext(BanksContext);
    const { isUpdating } = useSelector<AppState, BanksState>((state: AppState) => state.banks);

    const alertConfigs = useSelector<AppState, UserConfigState>((state: AppState) => state.imUserConfig);
    const userCompany = useSelector((state: AppState) => state.issuanceMonitor.amrPipelineCommon.userCompany);

    const withAnalyticsAccess = hasBankAnalyticsAlertsChangeAccess(bank, userCompany);
    const withDiAccess = user.hasFeatures(SubscriptionFeature.InventoryAlerts);
    const withImApAccess = user.hasAllFeatures(...bankImApRequiredFeatures);

    const withAlerts = withTurnedOnAlerts([
        ...(withAnalyticsAccess ? alertConfigs.bankAnalyticsAlert.value : []),
        ...(withImApAccess ? alertConfigs.bankArrangerPipelineAlert.value : []),
        ...(withImApAccess ? alertConfigs.bankIssuanceMonitorAlert.value : []),
        ...(withDiAccess ? alertConfigs.bankDealerInventoryAlert.value : []),
    ], bank.referenceName);

    const renderEditButtons = () => {
        if (edit) {
            return (
                <>
                    <button className="btn btn-ghost btn-sm" onClick={onCancel} disabled={isUpdating}>
                        Cancel
                    </button>
                    <button className="btn btn-main btn-sm" onClick={onSave} disabled={isUpdating}>
                        Save
                    </button>
                </>
            );
        }

        return (
            <button className="btn btn-ghost btn-sm" onClick={onEdit}>
                Edit
            </button>
        );
    };

    const defaultHeader = (
        <h1>{bank.legalName}</h1>
    );

    const editHeader = (
        <>
            <h1>Edit Bank</h1>
            <span className="name-tag">{bank.legalName}</span>
        </>
    );

    const alertsButton = (
        <BanksAlertsButton
            label="Alerts"
            isAlertOn={withAlerts}
            onClick={() => dispatch(banksActions.setBankForAlertPopup(bank.referenceName))}
        />
    );

    const spacer = (<div className="vertical-divider" />);

    const renderActions = () => {
        if (!canEdit) {
            return alertsButton;
        }

        return (
            <>
                {!edit && alertsButton}
                {!edit && spacer}
                {renderEditButtons()}
            </>
        );
    };

    return (
        <div className="sub-header">
            <div className="sub-header-row type04 flex-row">
                {bank.isUserCompany && <div className="sidebar-controls-item"><span className="bwic-state-my">MY</span></div>}
                <BanksLabels bank={bank} showActivities={false} />
                {edit ? editHeader : defaultHeader}
                <div className="flex-item-right flex-row">
                    {renderActions()}
                </div>
            </div>
        </div>
    );
};

export default BanksHeader;
