import { isEqual } from 'lodash';
import { FilterClear, FilterPanel, FilterSection } from '../../../../filters';
import { YearSelector } from '../../../../controls/YearSelector';
import { DropDownList, DropDownListItem } from '../../../../controls/DropDownList';
import { CollateralType } from '../../../../../types/amr-pipeline/enums/CollateralType';
import { Currency } from '../../../../../types/enums/Currency';
import { getAvailableYears } from '../../../../dashboard/topIssuers/top-list.utils';
import { ManagersTransactionCountFilterValues } from '../../../../../types/banks/ManagersTransactionCountFilterValues';
import { defaultFilterValues } from '../../../../../constants/banks/managerTransactionsFilter';
import { constants } from '../../../../../constants';

interface ManagersTransactionCountFilterProps extends ManagersTransactionCountFilterValues {
    onChange: (value: ManagersTransactionCountFilterValues, filterChanged: boolean) => void;
    disabled?: boolean;
}

const CollateralTypesList = [
    {
        text: 'All',
        value: constants.allListItem,
    },
    {
        text: 'BSL',
        value: CollateralType.broadlySyndicated,
    },
    {
        text: 'MM',
        value: CollateralType.middleMarket,
    }
];

const CurrencyList = [
    {
        text: 'All',
        value: constants.allListItem,
    },
    {
        text: 'USD',
        value: Currency.USD,
    },
    {
        text: 'EUR',
        value: Currency.EUR,
    }
];

const AvailableYears = getAvailableYears(2011);

export function ManagersTransactionCountFilter({
    onChange,
    disabled,
    ...filters
}: ManagersTransactionCountFilterProps) {
    const isFilterChanged = !isEqual(filters, defaultFilterValues);

    const handleValueChange = (prop: keyof ManagersTransactionCountFilterValues) => (value: unknown) => {
        onChange({
            ...filters,
            [prop]: value,
        }, isFilterChanged);
    };

    const handleReset = () => {
        onChange(defaultFilterValues, false);
    };

    const renderSelectedWithPrefix = (prefix: string) =>
        (item: DropDownListItem) => `${prefix}: ${item.text}`;

    return (
        <FilterPanel>
            <FilterSection>
                <DropDownList
                    items={CurrencyList.map(x => ({ ...x, selected: filters.currency === x.value }))}
                    defaultSortingEnabled={false}
                    onChange={({ value }) => handleValueChange('currency')(value)}
                    renderSelectedItemCallback={renderSelectedWithPrefix('Ccy')}
                    disabled={disabled}
                    className="custom-drop-down-ghost"
                />
            </FilterSection>
            <FilterSection>
                <DropDownList
                    items={CollateralTypesList.map(x => ({ ...x, selected: filters.collateralType === x.value }))}
                    defaultSortingEnabled={false}
                    onChange={({ value }) => handleValueChange('collateralType')(value)}
                    renderSelectedItemCallback={renderSelectedWithPrefix('Collat.')}
                    disabled={disabled}
                    className="custom-drop-down-ghost"
                />
            </FilterSection>
            <FilterSection>
                <YearSelector
                    years={AvailableYears}
                    selectedYear={filters.year}
                    currentYearPrefix="YTD"
                    onChange={handleValueChange('year')}
                    disabled={disabled}
                    showAllOption
                    prefix="Year: "
                />
            </FilterSection>
            <FilterClear
                isShown={isFilterChanged}
                isDisabled={disabled}
                onClick={handleReset}
            />
        </FilterPanel>
    );
}
